import { CreateEventFormStore } from "@/organization/occurrence/create-form/CreateEventForm"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { DiscoButton, DiscoButtonSkeleton, DiscoDropdown, DiscoTooltip } from "@disco-ui"
import DiscoButtonsGroup from "@disco-ui/button/DiscoButtonsGroup"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface Props extends TestIDProps {
  form: CreateEventFormStore
}

const CreatePublishedOrDraftEventButton = ({
  form,
  testid = "CreatePublishedOrDraftEventButton",
}: Props) => {
  const classes = useStyles()

  const isEventLocationTbd = form.state.meetingTab === "tbd"
  const isPublishing = form.state.status === "published"
  const isAttemptingToPublishTbd = isEventLocationTbd && isPublishing
  const isCommunityEvent = !form.state.productId

  return (
    <DiscoTooltip
      disabled={!isAttemptingToPublishTbd}
      content={
        "Can not publish with location to be determined. Select a location or save draft."
      }
    >
      <div>
        <DiscoDropdown
          testid={testid}
          disabled={isDisabled()}
          shouldDisplaySpinner={form.isSubmitting}
          menuButton={({ onClick, disabled, shouldDisplaySpinner }) => (
            <DiscoButtonsGroup
              noPadding
              buttons={[
                {
                  uniqueKey: "submit-button",
                  content: (
                    <Form.SubmitButton
                      form={form}
                      testid={`${testid}.submit-button`}
                      disabled={disabled || isAttemptingToPublishTbd}
                      shouldDisplaySpinner={shouldDisplaySpinner}
                    >
                      {isPublishing ? "Publish" : "Save Draft"}
                    </Form.SubmitButton>
                  ),
                },
                {
                  uniqueKey: "dropdown-button",
                  content: (
                    <DiscoButton
                      testid={`${testid}.dropdown-button`}
                      disabled={disabled}
                      className={classes.iconButton}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        onClick(e)
                      }}
                      leftIcon={"arrow-down"}
                    />
                  ),
                },
              ]}
            />
          )}
        >
          <DiscoDropdownItem
            testid={`${testid}.${isPublishing ? "save-draft" : "publish"}`}
            title={isPublishing ? "Save Draft" : "Publish"}
            onClick={() => {
              form.state.status = isPublishing ? "draft" : "published"
            }}
          />
        </DiscoDropdown>
      </div>
    </DiscoTooltip>
  )

  function isDisabled() {
    // If destination is product but no product selected
    if (form.state.destination === "product" && !form.state.productId) return true

    // If destination is community but no community event app selected
    if (form.state.destination === "community" && !form.state.appId) return true

    // If public, the user must acknowledge that the event will be public
    if (
      form.state.visibility === "public" &&
      !form.state.publicAcknowledgment &&
      !isCommunityEvent
    )
      return true

    if (form.state.visibility === null || form.state.visibility === null) return true

    return form.disabled
  }
}

const useStyles = makeUseStyles((theme) => ({
  iconButton: {
    padding: theme.spacing(1),
  },
}))

export default Relay.withSkeleton({
  component: observer(CreatePublishedOrDraftEventButton),
  skeleton: () => <DiscoButtonSkeleton />,
})
