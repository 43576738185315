import Badge from "@/admin/experiences/badges/Badge"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { EventAppSelectQuery } from "@/organization/occurrence/edit-form/__generated__/EventAppSelectQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import {
  DiscoSelect,
  DiscoSelectSkeleton,
  DiscoText,
  DiscoTooltip,
  SelectOption,
} from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import React from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  value: string | null | undefined
  onChange: (appId: string | null) => void
  testid?: string
  disabled?: boolean
}

const EventAppSelect: React.FC<Props> = ({
  testid = "EventAppSelect",
  value,
  onChange,
}) => {
  const activeOrganization = useActiveOrganization()!

  const { organization } = useLazyLoadQuery<EventAppSelectQuery>(
    graphql`
      query EventAppSelectQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            eventApps: apps(kind: organization_events, includeNested: true) {
              edges {
                node {
                  id
                  customAppTitle
                  badge {
                    ...BadgeFragment
                  }
                  navSection {
                    title
                  }
                }
              }
            }
          }
        }
      }
    `,
    { id: activeOrganization.id },
    { fetchPolicy: "network-only" }
  )

  const apps = Relay.connectionToArray(organization?.eventApps)
  const appsById = ArrayUtils.mapBy(apps, "id")
  const noApps = apps.length === 0
  const placeholder = noApps ? "No event apps" : "Select event app"

  const options = apps.map((app) => ({
    value: app.id,
    title: app.customAppTitle || "Community Events",
    searchable: [app.customAppTitle, app.navSection?.title],
  }))

  return (
    <DiscoTooltip
      titleVariant={"body-sm-600"}
      title={noApps ? "No Event Apps Available" : null}
      content={
        noApps
          ? "To create app events, you need at least one Events app in the Community."
          : null
      }
    >
      <span>
        <DiscoSelect
          testid={`${testid}.select`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disableClearable
          options={options}
          renderOption={renderOption}
          renderValue={renderOption}
          disabled={noApps}
        />
      </span>
    </DiscoTooltip>
  )

  function renderOption(option: SelectOption<GlobalID>) {
    const app = appsById[option.value]

    return (
      <DiscoDropdownItem
        testid={`${testid}.option.${option.title}`}
        backgroundColor={"transparent"}
        icon={<Badge badgeKey={app.badge!} size={24} />}
        title={
          <DiscoText variant={"body-md-600"}>
            {app.customAppTitle || "Community Events"}
          </DiscoText>
        }
        subtitle={app.navSection?.title}
      />
    )
  }
}

function EventAppSelectSkeleton() {
  return <DiscoSelectSkeleton />
}

export default Relay.withSkeleton({
  component: EventAppSelect,
  skeleton: EventAppSelectSkeleton,
})
