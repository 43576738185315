import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabels } from "@/core/context/LabelsContext"
import { CreateEventFormStore } from "@/organization/occurrence/create-form/CreateEventForm"
import { EventAccessFormFields_AppFragment$key } from "@/organization/occurrence/edit-form/__generated__/EventAccessFormFields_AppFragment.graphql"
import { EventAccessFormFields_OrganizationFragment$key } from "@/organization/occurrence/edit-form/__generated__/EventAccessFormFields_OrganizationFragment.graphql"
import {
  EventAccessFormFields_ProductFragment$key,
  ProductType,
} from "@/organization/occurrence/edit-form/__generated__/EventAccessFormFields_ProductFragment.graphql"
import { EditEventDrawerFormStore } from "@/organization/occurrence/event-drawer/EditEventDrawerContext"
import { EventVisibility } from "@/organization/occurrence/util/eventTypes"
import MemberGroupsMultiSelect from "@/product/common/member-group/modal/components/MemberGroupsMultiSelect"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoAlert,
  DiscoCheckbox,
  DiscoFormControl,
  DiscoIcon,
  DiscoIconKinds,
  DiscoRadio,
  DiscoSelect,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import { RadioGroup, useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { useEffect, useRef, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type Props = TestIDProps & {
  form: CreateEventFormStore | EditEventDrawerFormStore
  productKey?: EventAccessFormFields_ProductFragment$key | null
  organizationKey?: EventAccessFormFields_OrganizationFragment$key | null
  appKey?: EventAccessFormFields_AppFragment$key | null
}

interface EventVisibilityOption {
  value: EventVisibility
  title: string
  subtitle: string
  icon: DiscoIconKinds
}

function EventAccessFormFields({
  form,
  productKey = null,
  organizationKey = null,
  appKey = null,
  testid = "EventAccessFormFields",
}: Props) {
  const classes = useStyles()
  const { admin_experience: experienceLabel, admin_member: membersLabel } = useLabels()

  const activeOrganization = useActiveOrganization()
  const [hasAtleastOneMGM, setHasAtleastOneMGM] = useState<boolean | undefined>()
  const publicRef = useRef<HTMLDivElement>(null)
  const groupRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()

  const app = useFragment<EventAccessFormFields_AppFragment$key>(
    graphql`
      fragment EventAccessFormFields_AppFragment on ProductApp {
        visibility
        ...MemberGroupsMultiSelect_AppFragment
      }
    `,
    appKey
  )

  const product = useFragment<EventAccessFormFields_ProductFragment$key>(
    graphql`
      fragment EventAccessFormFields_ProductFragment on Product {
        name
        registrationAvailability
        type
        ...MemberGroupsMultiSelect_ProductFragment
      }
    `,
    productKey
  )

  const isCommunityEvent = product?.type === "community_event" || !form.state.productId
  const organization = useFragment<EventAccessFormFields_OrganizationFragment$key>(
    graphql`
      fragment EventAccessFormFields_OrganizationFragment on Organization {
        name
        ...MemberGroupsMultiSelect_OrganizationFragment
      }
    `,
    organizationKey
  )

  const canManageGroups =
    activeOrganization?.viewerPermissions.has("member_groups.manage")

  const options: EventVisibilityOption[] = [
    {
      value: "cohort",
      title: `All ${isCommunityEvent ? "Community" : experienceLabel.singular} Members`,
      subtitle: `Everyone in ${
        isCommunityEvent ? "your Community" : `the ${experienceLabel.singular}`
      } can see and attend this event.`,
      icon: "users",
    },
    {
      value: "member_group",
      title: "Private",
      subtitle: `Only ${membersLabel.plural} of a specific groups/sub-groups can see and attend this event.`,
      icon: "lock",
    },
    {
      value: "public",
      title: "Public",
      subtitle: "Anyone on the Internet with the link can see and attend this event.",
      icon: "globe",
    },
  ]

  useEffect(() => {
    if (!form.isChanged) return
    if (publicRef.current) publicRef.current.scrollIntoView({ behavior: "smooth" })
    if (groupRef.current) groupRef.current.scrollIntoView({ behavior: "smooth" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.visibility])

  return (
    <DiscoFormControl
      title={"Event Access"}
      titleTooltip={{
        label: "Choose who has access to see and register for this event.",
        placement: "right",
      }}
      errorMessages={
        form.state.visibility === "member_group"
          ? form.errorsByField.visibility ||
            form.errorsByField.memberGroupIdsToAdd ||
            form.errorsByField.memberGroupIdsToRemove
          : form.errorsByField.visibility
      }
    >
      {/* Access Disco dropdown */}
      <DiscoSelect
        className={classes.selectWrapper}
        autoComplete={false}
        testid={`${testid}.select`}
        disableClearable
        value={form.state.visibility}
        onChange={(value) => {
          form.state.visibility = value! as EventVisibility
          if (!isCommunityEvent) return
          if (value === "public") {
            form.state.allowGuestAccess = true
          } else {
            form.state.allowGuestAccess = false
          }
        }}
        options={
          requiresPublicProduct()
            ? options.filter((option) => option.value !== "public")
            : options // hide option for public if product is not public
        }
        renderOption={renderOption}
        renderValue={renderValue}
        // Was determined that changing event app visibility after creation
        // could have side effects on events within the app, and would require
        // intervention from the creator to update the individual events visiblity
        // Ref: https://www.figma.com/file/1TrLirIwM7iWLpX88Da6aX?type=design&node-id=3922-24455&mode=dev#773843314
        disabled={Boolean(app && app.visibility !== "all")}
      />

      {/* public requiresPublicProduct */}
      {form.state.visibility === "member_group" && (
        <div className={classes.paper}>
          <div className={classes.subtitle}>
            <DiscoText variant={"body-sm"} color={"text.secondary"} component={"span"}>
              {form.state.appId && !form.state.productId
                ? "This event is only visible to the groups specified in the app's access setting."
                : "Select groups/sub-groups that can see and attend this event."}
            </DiscoText>

            {form.state.appId && !form.state.productId && (
              <DiscoTooltip
                content={`Only groups that were specified in the app's access settings can be added here.`}
              />
            )}
          </div>
          <MemberGroupsMultiSelect
            selectedGroupIds={form.state.selectedMemberGroupIds}
            setSelectedGroupIds={handleSelectedGroupIds}
            productKey={isCommunityEvent ? null : product}
            organizationKey={
              isCommunityEvent && app?.visibility !== "membership" ? organization : null
            }
            appKey={app?.visibility === "membership" ? app : null}
            disabled={form.state.visibility !== "member_group"}
            // org admins can select any group whether or not they are a member
            requireGroupMembership={!canManageGroups}
            // track whether the org admin has at least one membership in the selected groups
            setHasAtleastOneMGMForSelected={
              canManageGroups ? setHasAtleastOneMGM : undefined
            }
            disableAdminOnlyGroups
          />

          {/* display an alert if the user has no membership for the selected groups */}
          {hasAtleastOneMGM === false &&
            Boolean(form.state.selectedMemberGroupIds.length) &&
            form.state.visibility === "member_group" && (
              <DiscoAlert
                data-testid={`${testid}.Warning.no-member-group-membership`}
                severity={"warning"}
                marginTop={1.5}
                message={getAlertText()}
              />
            )}
        </div>
      )}

      {/* Guest Access */}
      {form.state.visibility === "public" && (
        <div ref={publicRef} className={classes.paper}>
          {isCommunityEvent ? (
            <>
              <DiscoText variant={"body-sm"} marginBottom={1} color={"text.secondary"}>
                {`This event is visible on the Internet.  What happens when a non-member registers for this event?`}
              </DiscoText>
              <RadioGroup
                className={classes.radioGroupWrapper}
                value={form.state.allowGuestAccess}
                onChange={handleGuestAccessChange}
              >
                {/* Allow Guest Acess */}
                <DiscoRadio
                  testid={`${testid}.GuestAccess`}
                  classes={{ label: classes.radioLabel }}
                  variant={"simple"}
                  sublabel={
                    "These guests can attend this event only and do not have access to your community."
                  }
                  label={`Grant limited "guest access" (Recommended)`}
                  value={true}
                />
                {/* Attendees can becomes members */}
                <DiscoRadio
                  testid={`${testid}.FullAccess`}
                  classes={{ label: classes.radioLabel }}
                  variant={"simple"}
                  label={`Grant full member access`}
                  sublabel={
                    "They can attend this event, but are granted full access as a member to your community."
                  }
                  value={false}
                />
              </RadioGroup>
            </>
          ) : (
            <DiscoCheckbox
              testid={`${testid}.publicAcknowledgment`}
              checked={form.state.publicAcknowledgment}
              onChange={(checked) => (form.state.publicAcknowledgment = checked)}
              label={`I acknowledge that anyone from the Internet can join this event.`}
            />
          )}
        </div>
      )}
    </DiscoFormControl>
  )

  function renderValue(option: EventVisibilityOption) {
    return (
      <div className={classes.valueContainer}>
        {option.icon && (
          <DiscoIcon
            icon={option.icon}
            color={theme.palette.groovy.neutral[700]}
            className={classes.valueIcon}
          />
        )}
        <DiscoText variant={"body-sm-500"} className={classes.optionTitle}>
          {option.title}
        </DiscoText>
      </div>
    )
  }
  function renderOption(option: EventVisibilityOption) {
    return (
      <div className={classes.optionContainer} data-testid={`${testid}.${option.value}`}>
        {option.icon && (
          <DiscoIcon
            icon={option.icon}
            color={theme.palette.groovy.neutral[700]}
            className={classes.optionIcon}
          />
        )}
        <div>
          <DiscoText variant={"body-md-600"} className={classes.optionTitle}>
            {option.title}
          </DiscoText>
          {option.subtitle && (
            <DiscoText variant={"body-sm-500"} className={classes.optionSubtitle}>
              {option.subtitle}
            </DiscoText>
          )}
        </div>
      </div>
    )
  }
  function handleSelectedGroupIds(selectedMemberGroupIds: string[]) {
    form.state.selectedMemberGroupIds.replace(selectedMemberGroupIds)
  }

  function getAlertText() {
    const group = pluralize("group", form.state.selectedMemberGroupIds.length)
    if (isCommunityEvent) {
      return `You're not a member of the selected ${group} but can still access it from the Events page.`
    }

    return `You're not a member of the selected ${group} but can still access it from the ${experienceLabel.singular} Event tab.`
  }

  function requiresPublicProduct() {
    if (!product) return false
    if (isCommunityEvent) return false
    if ((product.type as ProductType) !== "community_event") return false

    return product.registrationAvailability !== "public"
  }

  function handleGuestAccessChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value === "true"
    form.state.allowGuestAccess = value
  }
}

const useStyles = makeUseStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    background: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.large,
    boxShadow: theme.palette.groovyDepths.insideCard,
    padding: theme.spacing(2),
  },
  radioLabel: {
    fontWeight: 600,
    fontSize: "14px",
    height: 24,
    marginTop: theme.spacing(0.75),
  },
  radioGroupWrapper: {
    marginBottom: theme.spacing(1.5),
  },
  selectWrapper: {
    marginBottom: theme.spacing(1.5),
  },
  valueContainer: {
    display: "flex",
    alignItems: "start",
  },
  optionContainer: {
    display: "flex",
    alignItems: "start",
    margin: theme.spacing(1),
  },
  valueIcon: {
    height: "20px",
    width: "20px",
    marginRight: theme.spacing(1),
  },
  optionIcon: {
    height: "24px",
    width: "24px",
    marginRight: theme.spacing(1),
  },
  optionTitle: {
    marginRight: theme.spacing(0.5),
  },
  optionSubtitle: {
    color: theme.palette.text.secondary,
  },
  subtitle: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
}))

export default observer(EventAccessFormFields)
