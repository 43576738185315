/**
 * @generated SignedSource<<195cacd9e9b82abe73c01600793510cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OccurrenceRSVPAttendanceStatusFragment$data = {
  readonly id: string;
  readonly product: {
    readonly id: string;
    readonly slug: string;
    readonly type: ProductType;
  };
  readonly " $fragmentSpreads": FragmentRefs<"RSVPToEventButtonFragment" | "occurrenceUtils_useOccurrenceStatusFragment" | "OccurrenceRegisterAndRSVPButtonFragment" | "OccurrenceAttendingButtonFragment">;
  readonly " $fragmentType": "OccurrenceRSVPAttendanceStatusFragment";
};
export type OccurrenceRSVPAttendanceStatusFragment$key = {
  readonly " $data"?: OccurrenceRSVPAttendanceStatusFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OccurrenceRSVPAttendanceStatusFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OccurrenceRSVPAttendanceStatusFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RSVPToEventButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "occurrenceUtils_useOccurrenceStatusFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OccurrenceRegisterAndRSVPButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OccurrenceAttendingButtonFragment"
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};
})();

(node as any).hash = "cfc21d2efba0a935c5180e830d9736d2";

export default node;
