import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import ProductSelect from "@/product/common/ProductSelect"
import React from "react"

interface Props {
  value: string | null | undefined
  onChange: (productId: string | null) => void
  testid?: string
}

const EventProductSelect: React.FC<Props> = ({ value, onChange, testid }) => {
  const activeOrganization = useActiveOrganization()!
  const isAdmin = activeOrganization?.viewerIsOwnerOrAdmin
  const experienceLabel = useLabel("admin_experience")

  return (
    <ProductSelect
      value={value}
      onChange={onChange}
      type={"course"}
      hideNonEventAppProducts
      viewerPermission={"events.create"}
      organizationId={activeOrganization.id}
      hideNonPublic={!isAdmin}
      placeholder={`Select ${experienceLabel.singular}`}
      testid={testid}
      emptyText={`No ${experienceLabel.plural} available`}
      emptyTooltipContent={`Only community-wide events can be created at the moment. To create ${experienceLabel.singular} events, you need to manage at least one ${experienceLabel.singular} with an Events app.`}
    />
  )
}

export default EventProductSelect
