/**
 * @generated SignedSource<<991be7282bd6c319a5f3358b4d2ca0fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GoogleMapsSuggestionsInput = {
  productId?: string | null;
  organizationId: string;
  query: string;
};
export type OccurrencePhysicalAddressInputSuggestionsQuery$variables = {
  input: GoogleMapsSuggestionsInput;
};
export type OccurrencePhysicalAddressInputSuggestionsQuery$data = {
  readonly googleMapsSuggestions: ReadonlyArray<{
    readonly displayAddress: string;
    readonly placeId: string;
  }>;
};
export type OccurrencePhysicalAddressInputSuggestionsQuery = {
  variables: OccurrencePhysicalAddressInputSuggestionsQuery$variables;
  response: OccurrencePhysicalAddressInputSuggestionsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GoogleMapsSuggestionsResponse",
    "kind": "LinkedField",
    "name": "googleMapsSuggestions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "placeId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OccurrencePhysicalAddressInputSuggestionsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OccurrencePhysicalAddressInputSuggestionsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fa55543f92ff6cd6efa4cad95f5c1a5a",
    "id": null,
    "metadata": {},
    "name": "OccurrencePhysicalAddressInputSuggestionsQuery",
    "operationKind": "query",
    "text": "query OccurrencePhysicalAddressInputSuggestionsQuery(\n  $input: GoogleMapsSuggestionsInput!\n) {\n  googleMapsSuggestions(input: $input) {\n    displayAddress\n    placeId\n  }\n}\n"
  }
};
})();

(node as any).hash = "7d13c9dd59ceeec73defcdf53003faa2";

export default node;
