import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { CreateEventFormStore } from "@/organization/occurrence/create-form/CreateEventForm"
import EventReminderFormField from "@/organization/occurrence/edit-form/EventReminderFormField"
import { EditEventDrawerFormStore } from "@/organization/occurrence/event-drawer/EditEventDrawerContext"
import EditorUtils from "@components/editor/EditorUtils"
import { DiscoButton, DiscoFormControl } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"

interface Props extends TestIDProps {
  form: EditEventDrawerFormStore | CreateEventFormStore
}

function EventReminderFormSection(props: Props) {
  const { form, testid = "EventReminderFormSection" } = props

  const activeOrganization = useActiveOrganization()!

  // if date/time of the event is changed, clear the 'sentAt' of any reminders
  // since they will be triggered to resend at the new scheduled time
  useEffect(() => {
    if (form.changedState.timezone || form.changedState.startDatetime) {
      form.state.reminders?.forEach((r) => (r.sentAt = null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.timezone, form.state.startDatetime])

  return (
    <DiscoFormControl
      title={"Reminders"}
      label={
        activeOrganization.hasSlackConnection
          ? "Schedule reminder emails and Slack messages before this event starts."
          : "Schedule reminder emails before this event starts."
      }
    >
      {form.state.reminders?.map((reminder, i) => (
        <EventReminderFormField
          key={reminder.id || i}
          form={form}
          reminder={reminder}
          testid={`${testid}.reminder.${i}`}
        />
      ))}
      <DiscoButton
        color={"grey"}
        variant={"dashed"}
        leftIcon={"add-circle"}
        onClick={handleAddReminder}
        data-testid={`${testid}.addReminder`}
      >
        {"Add Reminder"}
      </DiscoButton>
    </DiscoFormControl>
  )

  function handleAddReminder() {
    form.state.reminders = form.state.reminders || observable.array()
    form.state.reminders.push({
      timing: "24h",
      audience: "all",
      emailSubject: "{{ event.name }} is coming up",
      emailRichEditorBody: JSON.stringify(
        EditorUtils.createParagraphs([
          { text: "Hi {{ user.firstName }},", align: "center" },
          {
            text: "This is a reminder that the event {{ event.name }} is coming up ({{ event.dateAndTime }}).",
            align: "center",
          },
        ])
      ),
      sentAt: null,
    })
  }
}

export default observer(EventReminderFormSection)
