import CreateEventForm from "@/organization/occurrence/create-form/CreateEventForm"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoModal } from "@disco-ui"
import { useTheme } from "@material-ui/core"

export type EventVariant = "product" | "community"

export type CreateEventModalProps = {
  testid?: string
  onClose?: () => void
  onCreate?: (occurrence: { id: GlobalID; datetimeRange: readonly string[] }) => void
  eventVariant?: EventVariant
}

function CreateEventModal(props: CreateEventModalProps) {
  const { testid = "CreateEventModal", onClose, onCreate } = props

  const theme = useTheme()

  return (
    <DiscoModal
      testid={`${testid}.modal`}
      isOpen
      modalContentLabel={"Create Event"}
      buttons
      body={<CreateEventForm onClose={onClose} onCreate={onCreate} />}
      onClose={onClose}
      width={"unset"}
      maxWidth={`${theme.measure.modalMaxWidth.large}px`}
    />
  )
}

export default Relay.withSkeleton({
  component: CreateEventModal,
  skeleton: () => null,
})
