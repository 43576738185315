import { useAuthUser } from "@/core/context/AuthUserContext"
import { ObservableState, useFormStore } from "@/core/form/store/FormStore"
import { OccurrenceReminderInput } from "@/organization/occurrence/__generated__/EventDrawerTitleMutation.graphql"
import { EventReminderPreviewEmailButtonMutation } from "@/organization/occurrence/edit-form/__generated__/EventReminderPreviewEmailButtonMutation.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  occurrenceId: GlobalID
  reminder: ObservableState<OccurrenceReminderInput>
}

function EventReminderPreviewEmailButton(props: Props) {
  const { reminder, occurrenceId, testid = "EventReminderPreviewEmailButton" } = props

  const { authUser } = useAuthUser({ required: true })

  const previewEmailForm = useFormStore<EventReminderPreviewEmailButtonMutation>(
    graphql`
      mutation EventReminderPreviewEmailButtonMutation(
        $input: PreviewOccurrenceReminderInput!
      ) {
        response: previewOccurrenceReminder(input: $input) {
          data
          errors {
            field
            message
          }
        }
      }
    `,
    {
      occurrenceId,
      subject: "",
      richEditorBody: "",
    }
  )

  return (
    <DiscoButton
      testid={testid}
      color={"transparent"}
      disabled={previewEmailForm.isSubmitting}
      shouldDisplaySpinner={previewEmailForm.isSubmitting}
      onClick={handlePreviewButtonClick}
      leftIcon={"send"}
    >
      {"Send Me Preview"}
    </DiscoButton>
  )

  async function handlePreviewButtonClick() {
    const { didSave: didSend } = await previewEmailForm.submit({
      occurrenceId,
      subject: reminder.emailSubject,
      richEditorBody: reminder.emailRichEditorBody,
    })

    if (!didSend) return

    displaySuccessToast({
      message: `Preview email sent to ${authUser.email}`,
      testid: `${testid}.success-toast`,
    })
  }
}

export default observer(EventReminderPreviewEmailButton)
