import { CreateEventFormStore } from "@/organization/occurrence/create-form/CreateEventForm"
import { DiscoSelect, DiscoText, SelectOption } from "@disco-ui"
import { useState } from "react"
import { RRule } from "rrule"

export type FrequencyDropdownOption = SelectOption<string, { index: number }>

interface Props {
  testid: string
  form: CreateEventFormStore
  options: FrequencyDropdownOption[]
}

function RecurrenceFrequencyDropdown({ testid, form, options }: Props) {
  const { rruleOptions } = form.state.rruleConfig

  const [currentOption, setCurrentOption] = useState(options[0])

  return (
    <DiscoSelect
      testid={testid}
      placeholder={"Select Preset Recurrence"}
      disableClearable
      value={currentOption!.value}
      onChange={(value) =>
        handleSetCurrentOptions(options.find((o) => o.value === value)!)
      }
      options={options}
      renderOption={(option) => {
        return (
          <DiscoText truncateText={1} testid={`${testid}.item-${option.context!.index}`}>
            {option.title}
          </DiscoText>
        )
      }}
    />
  )

  function handleSetCurrentOptions(option: FrequencyDropdownOption | null) {
    if (option) {
      setCurrentOption(option)
      if (option) {
        switch (Number(option.value)) {
          case RRule.MONTHLY:
            form.state.rruleConfig.rruleOptions = {
              ...rruleOptions,
              ...options[0],
              freq: Number(option.value),
              byweekday: [],
            }
            break
          // weekly
          default:
            form.state.rruleConfig.rruleOptions = {
              ...rruleOptions,
              bymonthday: [],
              bysetpos: [],
              freq: Number(option.value),
              byweekday: rruleOptions?.byweekday || [],
            }
        }
      }
    }
  }
}

export default RecurrenceFrequencyDropdown
