/**
 * @generated SignedSource<<15732a0318398193df0a378697637765>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OccurrenceStatusChipFragment$data = {
  readonly id: string;
  readonly datetimeRange: ReadonlyArray<string>;
  readonly " $fragmentSpreads": FragmentRefs<"occurrenceUtils_useOccurrenceStatusFragment">;
  readonly " $fragmentType": "OccurrenceStatusChipFragment";
};
export type OccurrenceStatusChipFragment$key = {
  readonly " $data"?: OccurrenceStatusChipFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OccurrenceStatusChipFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OccurrenceStatusChipFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "datetimeRange",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "occurrenceUtils_useOccurrenceStatusFragment"
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};

(node as any).hash = "8e4cb3d721d3d0734b9f35b16650945c";

export default node;
