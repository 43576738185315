import { RecurrenceDropdownOption } from "@/organization/occurrence/recurring-event-fields/MonthlyRecurrenceDropdown"
import { RRULE_DAYS } from "@/organization/occurrence/util/RecurringEventConstants"
import { addWeeks } from "date-fns"
import { RRule } from "rrule"
import { Frequency, ParsedOptions } from "rrule/dist/esm/types"

/** Given a date returns the correct RRule Day constant. */
export function getRRuleConstantDayFromDate(date: Date) {
  const currentDay = date.getDay()

  // The first day of the week for Date is sunday, for RRule the first day of the month is Monday. so if we get Sunday from Date, we make that 6 which is RRule sunday
  return RRULE_DAYS[currentDay === 0 ? 6 : currentDay - 1]
}

/** Given a date, this function gives us the nth name day of the month. For example, today is Tuesday, November the 1st so its the first Tuesday of the month*/
export function getNthWeekdayOfTheMonth(date: Date) {
  return Math.ceil(date.getDate() / 7)
}

/** Is this the last day of it's kind in the month? i.e. the last Thursday of the month> */
export function isLastDayOfKindInTheMonth(date: Date): boolean {
  const month = date.getMonth()
  const nextWeekMonth = addWeeks(date, 1).getMonth()
  return month !== nextWeekMonth
}

/** Gives Recurrence rules, this function returns dropdown options*/

export function mapRRulesIntoSelectOptions(
  rules: Partial<ParsedOptions>[],
  date: Date = new Date()
): RecurrenceDropdownOption[] {
  return rules.map((rule, index) => {
    if (rule.freq === undefined) {
      return {
        value: "custom-rule",
        title: "Custom",
        context: {
          rule: {
            freq: RRule.MONTHLY,
          },
          index,
        },
      }
    }
    // Checks for the rules that break the toText of the rrule library so that we can create our own
    const newRule = new RRule(rule)
    if (rule.byweekday !== undefined && rule.bysetpos?.[0] !== undefined) {
      return {
        value: newRule.toString(),
        title: `Monthly on the ${getOrdinalText(rule.bysetpos[0])} ${getDayName(date)}`,
        context: { index },
      }
    }
    return {
      value: newRule.toString(),
      title: newRule.toText()[0].toUpperCase() + newRule.toText().slice(1),
      context: { index },
    }
  })
}

/** Gets the name of the current day or the day of the date that is passed in */
function getDayName(date = new Date(), locale = "en-US") {
  return date.toLocaleDateString(locale, { weekday: "long" })
}

/** Gets the ordinal text of a number. For example passing in 1 will give you first. This is limited to 5  */
function getOrdinalText(i: number) {
  // Since we're only using this for nth weekday of the month, we only go up to fourth and last here, otherwise we'd have to go further as ordinals aren't regular enough
  if (i === -1) {
    return "last"
  }
  const ordinals = ["first", "second", "third", "fourth", "fifth"]
  return ordinals[i - 1]
}

/** Return the correct string given the RRULE FREQ */
export function getRuleType(freq: Frequency) {
  switch (freq) {
    case RRule.MONTHLY:
      return "monthly"
    case RRule.YEARLY:
      return "yearly"
    case RRule.WEEKLY:
      return "weekly"
    default:
      return "daily"
  }
}
