import OrganizationMemberMultiSelect from "@/organization/member/common/OrganizationMemberMultiSelect"
import { CreateEventFormStore } from "@/organization/occurrence/create-form/CreateEventForm"
import { EditEventDrawerFormStore } from "@/organization/occurrence/event-drawer/EditEventDrawerContext"
import ProductMemberMultiSelect from "@/product/member/common/ProductMemberMultiSelect"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoAlert, DiscoFormControl, DiscoFormControlVariant } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import { graphql } from "relay-runtime"

type Props = {
  form: CreateEventFormStore | EditEventDrawerFormStore
  formControlVariant?: DiscoFormControlVariant
  productId?: GlobalID | null
} & TestIDProps

function EventHostsFormFields({
  form,
  productId = null,
  testid = "EventHostsFormFields",
  formControlVariant = "two-column",
}: Props) {
  return (
    <DiscoFormControl
      title={"Event Hosts"}
      titleTooltip={{
        label: `Add hosts to communicate to your attendees who will be running the event.  Note that this is for information purposes only and does not affect access or permissions.`,
        placement: "right",
      }}
      variant={formControlVariant}
      error={Boolean(form.errorsByField.hosts)}
      errorMessages={form.errorsByField.hosts}
    >
      <>
        {form.state.meetingTab === "zoom" && (
          <DiscoAlert
            data-testid={`${testid}.zoom-host-banner`}
            marginBottom={1.5}
            message={"Ensure the host(s) have permission to start the event in Zoom"}
          />
        )}
        {productId ? (
          <ProductMemberMultiSelect
            testid={`${testid}.EventHostMultiSelect`}
            placeholder={"Search"}
            productId={productId || ""}
            values={
              form.state.hosts?.map((h) => h.productMembershipId!).filter(Boolean) || []
            }
            onChange={handleUpdateHosts}
          />
        ) : (
          <OrganizationMemberMultiSelect
            testid={`${testid}.EventHostMultiSelect`}
            placeholder={"Search"}
            values={
              form.state.hosts?.map((h) => h.organizationMembershipId!).filter(Boolean) ||
              []
            }
            onChange={handleUpdateHosts}
          />
        )}
      </>
    </DiscoFormControl>
  )

  function handleUpdateHosts(memberships: { id: GlobalID }[]) {
    form.state.hosts = observable.array(
      memberships.map(({ id }) =>
        productId ? { productMembershipId: id } : { organizationMembershipId: id }
      )
    )
  }
}

export default observer(EventHostsFormFields)

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment EventHostsFormFields_Occurrence on Occurrence {
    hosts {
      edges {
        node {
          id
          productMembershipId
          organizationMembershipId
        }
      }
    }
  }
`
