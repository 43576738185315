import { CreateEventFormStore } from "@/organization/occurrence/create-form/CreateEventForm"
import { DiscoSelect, DiscoText, SelectOption } from "@disco-ui"
import { useEffect, useState } from "react"
import { RRule, rrulestr } from "rrule"

export type RecurrenceDropdownOption = SelectOption<
  string,
  { index: number; rule?: Partial<RRule["options"]> }
>

interface Props {
  form: CreateEventFormStore
  testid: string
  options: RecurrenceDropdownOption[]
}

function MonthlyRecurrenceDropdown({ testid, form, options }: Props) {
  const { rruleOptions } = form.state.rruleConfig

  const [currentOption, setCurrentOption] = useState(options[0])

  useEffect(() => {
    switch (form.state.rruleConfig.currentMonthlyRuleType) {
      case "date":
        setCurrentOption(options[0])
        form.state.rruleConfig.rruleOptions = {
          ...rrulestr(options[0].value).options,
          until: rruleOptions?.until,
          count: rruleOptions?.count,
        }
        break
      case "day":
        setCurrentOption(options[1])
        form.state.rruleConfig.rruleOptions = {
          ...rrulestr(options[1].value).options,
          until: rruleOptions?.until,
          count: rruleOptions?.count,
        }
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  return (
    <DiscoSelect
      testid={testid}
      placeholder={"Select Preset Recurrence"}
      disableClearable
      value={currentOption!.value}
      onChange={(value) =>
        handleSetCurrentOptions(options.find((o) => o.value === value)!)
      }
      options={options}
      renderOption={(option) => {
        return (
          <DiscoText testid={`${testid}.item-${option.context!.index}`}>
            {option.title}
          </DiscoText>
        )
      }}
    />
  )

  function handleSetCurrentOptions(option: RecurrenceDropdownOption | null) {
    if (option) {
      setCurrentOption(option)
      form.state.rruleConfig.currentMonthlyRuleType = rrulestr(option.value).options
        .bysetpos
        ? "day"
        : "date"
      form.state.rruleConfig.rruleOptions = {
        ...rrulestr(option.value).options,
        until: rruleOptions?.until,
        count: rruleOptions?.count,
      }
    }
  }
}

export default MonthlyRecurrenceDropdown
