import { CreateEventFormStore } from "@/organization/occurrence/create-form/CreateEventForm"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl } from "@disco-ui"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import { TestIDProps } from "@utils/typeUtils"

interface ToggleRecurringFormFieldProps extends TestIDProps {
  form: CreateEventFormStore
  disabled?: boolean
}

function EventFrequencyFormFields({
  form,
  testid,
}: ToggleRecurringFormFieldProps): JSX.Element {
  const classes = useStyles()
  return (
    <DiscoFormControl
      marginBottom={0}
      title={"Event Type"}
      titleTooltip={{
        label: "Select if you want this to be a one-time event or a recurring event.",
        placement: "right",
      }}
    >
      <DiscoTabs
        tabVariant={"grey-track"}
        routes={[
          {
            label: "One-time Event",
            testid: "one-time-event",
            active: !form.state.isRecurring,
            onClick: () => {
              form.state.isRecurring = false
            },
          },
          {
            label: "Recurring Event",
            testid: "recurring-events",
            active: form.state.isRecurring,
            onClick: () => {
              form.state.isRecurring = true
            },
          },
        ]}
        testid={`${testid}.EventFrequency.tab-list`}
        classes={{ arrowsContainer: classes.arrowsContainer }}
      />
    </DiscoFormControl>
  )
}

const useStyles = makeUseStyles((theme) => ({
  arrowsContainer: {
    [theme.breakpoints.down("sm")]: {
      gridTemplateRows: "1fr max-content",
    },
  },
}))

export default EventFrequencyFormFields
