/**
 * @generated SignedSource<<f40d67cad9bcfc6875bd05c73d3539e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type OccurrenceStatus = "draft" | "published" | "%future added value";
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrganizationOccurrenceListPrevious_PaginationFragment$data = {
  readonly previousOccurrences: {
    readonly __id: string;
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly datetimeRange: ReadonlyArray<string>;
        readonly status: OccurrenceStatus;
        readonly product: {
          readonly slug: string;
          readonly status: ProductStatus;
        };
        readonly name: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"OrganizationOccurrenceListItem_OccurrenceFragment">;
      };
    }>;
    readonly pageInfo: {
      readonly startCursor: string | null;
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
    };
  };
  readonly id: string;
  readonly " $fragmentType": "OrganizationOccurrenceListPrevious_PaginationFragment";
};
export type OrganizationOccurrenceListPrevious_PaginationFragment$key = {
  readonly " $data"?: OrganizationOccurrenceListPrevious_PaginationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationOccurrenceListPrevious_PaginationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "previousOccurrences"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "appId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "datetimeFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "excludeCommunityEvents"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "excludeProductEvents"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hasRecording"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includedProductIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startsBefore"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./OrganizationOccurrenceListPreviousPaginationQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "OrganizationOccurrenceListPrevious_PaginationFragment",
  "selections": [
    {
      "alias": "previousOccurrences",
      "args": [
        {
          "kind": "Variable",
          "name": "appId",
          "variableName": "appId"
        },
        {
          "kind": "Variable",
          "name": "datetimeFilter",
          "variableName": "datetimeFilter"
        },
        {
          "kind": "Variable",
          "name": "excludeCommunityEvents",
          "variableName": "excludeCommunityEvents"
        },
        {
          "kind": "Variable",
          "name": "excludeProductEvents",
          "variableName": "excludeProductEvents"
        },
        {
          "kind": "Variable",
          "name": "hasRecording",
          "variableName": "hasRecording"
        },
        {
          "kind": "Variable",
          "name": "includedProductIds",
          "variableName": "includedProductIds"
        },
        {
          "kind": "Variable",
          "name": "startsBefore",
          "variableName": "startsBefore"
        }
      ],
      "concreteType": "OccurrenceNodeConnection",
      "kind": "LinkedField",
      "name": "__OrganizationOccurrenceList__previousOccurrences_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OccurrenceNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Occurrence",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "datetimeRange",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "slug",
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "OrganizationOccurrenceListItem_OccurrenceFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoObjectType",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "5d848d12670e3ec69ac5cebfb71105f4";

export default node;
