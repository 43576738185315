import { OrganizationOccurrenceListItem_OccurrenceFragment$key } from "@/organization/occurrence/__generated__/OrganizationOccurrenceListItem_OccurrenceFragment.graphql"
import OccurrenceListItem from "@/product/course/event/list/item/OccurrenceListItem"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoDivider, DiscoText } from "@disco-ui"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { TestIDProps } from "@utils/typeUtils"
import { isAfter, isBefore, isSameDay, isToday } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type DatePosition = "before-month" | "after-month" | "after-item"

export interface OrganizationOccurrenceListItemProps extends TestIDProps {
  occurrenceKey?: OrganizationOccurrenceListItem_OccurrenceFragment$key | null
  shouldShowMonthYear: boolean
  dateSeparator?: Date
  dateOrdering: "ASC" | "DESC"
}

function OrganizationOccurrenceListItem({
  testid,
  occurrenceKey,
  shouldShowMonthYear,
  dateSeparator,
  dateOrdering,
}: OrganizationOccurrenceListItemProps) {
  const timezone = useUserTimezone()

  const occurrence = useFragment<OrganizationOccurrenceListItem_OccurrenceFragment$key>(
    graphql`
      fragment OrganizationOccurrenceListItem_OccurrenceFragment on Occurrence {
        id
        datetimeRange
        ...OccurrenceListItemFragment
      }
    `,
    occurrenceKey || null
  )

  const startDate = new Date(occurrence?.datetimeRange[0] || 0)

  const datePosition = getDatePosition()

  const classes = useStyles({ datePosition })

  if (!occurrence) return renderDateSeparator()

  return (
    <div key={occurrence.id} className={classes.container}>
      {datePosition === "before-month" && renderDateSeparator()}
      {renderMonthYear()}
      {datePosition === "after-month" && renderDateSeparator()}
      <OccurrenceListItem
        key={occurrence.id}
        occurrenceKey={occurrence}
        testid={testid}
      />
      {datePosition === "after-item" && renderDateSeparator()}
    </div>
  )

  function getDatePosition(): DatePosition | null {
    if (!dateSeparator) return null

    if (isToday(dateSeparator) && isSameDay(dateSeparator, startDate)) {
      return "after-month"
    }

    if (isAfter(dateSeparator, startDate) && dateOrdering === "ASC") return "after-item"
    if (isBefore(dateSeparator, startDate) && dateOrdering === "DESC") return "after-item"
    if (dateSeparator.getMonth() === startDate.getMonth()) return "after-month"
    return "before-month"
  }

  function renderMonthYear() {
    if (!shouldShowMonthYear || !occurrence) return null

    const currentMonthYear = formatInTimeZone(
      occurrence.datetimeRange[0],
      timezone,
      DATE_FORMAT.FULL_MONTH_AND_YEAR
    )

    return (
      <DiscoText
        variant={"body-md-700"}
        paddingTop={1}
        paddingBottom={dateSeparator && datePosition === "after-month" ? 0 : 2}
        testid={`OccurrenceListDate.${currentMonthYear.replace(" ", "-")}`}
      >
        {currentMonthYear}
      </DiscoText>
    )
  }

  function renderDateSeparator() {
    if (!dateSeparator) return null

    const isSeparatorToday = isToday(dateSeparator)

    const label = isSeparatorToday
      ? "Today"
      : formatInTimeZone(dateSeparator, timezone, DATE_FORMAT.DEFAULT_FULL_MONTH)

    const isEventSameDayAsSeparator = isSameDay(startDate, dateSeparator)

    return (
      <div className={classes.separatorContainer}>
        <div className={classes.dateSeparator}>
          <DiscoDivider thickness={2} />
          <DiscoText
            testid={"OccurrenceListDate.today"}
            noWrap
            variant={"body-xs-600"}
            className={classes.date}
          >
            {label}
          </DiscoText>
        </div>
        {!isEventSameDayAsSeparator && (
          <div className={classes.emptyText}>
            <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
              {isSeparatorToday ? `No events today` : `No events on ${label}`}
            </DiscoText>
          </div>
        )}
      </div>
    )
  }
}

type StyleProps = {
  datePosition: DatePosition | null
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "grid",
  },
  dateSeparator: {
    position: "relative",
    padding: theme.spacing(1.5, 0),
  },
  separatorContainer: (props: StyleProps) => ({
    ...styleIf(props.datePosition !== "before-month", {
      paddingBottom: theme.spacing(2),
    }),
  }),
  date: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5, 2, 0.75),
    border: theme.palette.constants.borderSmall,
    borderRadius: theme.spacing(3),
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translateY(-50%) translateX(-50%)",
  },
  emptyText: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(3, 0),
  },
}))

export default OrganizationOccurrenceListItem
