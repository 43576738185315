import OrganizationZoomConnectionList from "@/admin/integrations/zoom/connection/OrganizationZoomConnectionList"
import OrganizationZoomConnectionListItem, {
  ZoomMeetingProviderDataShape,
} from "@/admin/integrations/zoom/connection/OrganizationZoomConnectionListItem"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import ZoomIcon from "@/core/ui/images/integration-icons/zoom-integration-icon.svg"
import CreateMeetingProviderButton from "@/meeting-provider/common/add/button/CreateMeetingProviderButton"
import { CreateEventFormStore } from "@/organization/occurrence/create-form/CreateEventForm"
import { EventVariant } from "@/organization/occurrence/create-form/CreateEventModal"
import { EventMeetingFormFields_OrganizationFragment$key } from "@/organization/occurrence/edit-form/__generated__/EventMeetingFormFields_OrganizationFragment.graphql"
import { EventMeetingFormFields_ProductFragment$key } from "@/organization/occurrence/edit-form/__generated__/EventMeetingFormFields_ProductFragment.graphql"
import EventHostsFormFields from "@/organization/occurrence/edit-form/EventHostsFormFields"
import { EditEventDrawerFormStore } from "@/organization/occurrence/event-drawer/EditEventDrawerContext"
import OccurrencePhysicalAddressInput from "@/organization/occurrence/OccurrencePhysicalAddressInput"
import { EventRoomChoices } from "@/organization/occurrence/util/eventTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import WithEntitlement from "@components/entitlement/WithEntitlement"
import {
  DiscoAlert,
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoFormControl,
  DiscoFormControlSkeleton,
  DiscoFormControlVariant,
  DiscoIcon,
  DiscoIconButton,
  DiscoLinkInput,
  DiscoRadio,
  DiscoText,
} from "@disco-ui"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import { Grid, Popover, RadioGroup } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import { graphql, useFragment } from "react-relay"

interface Props extends TestIDProps {
  productKey: EventMeetingFormFields_ProductFragment$key | null
  organizationKey: EventMeetingFormFields_OrganizationFragment$key | null
  form: CreateEventFormStore | EditEventDrawerFormStore
  formControlVariant?: DiscoFormControlVariant
  eventVariant: EventVariant
  hideHosts?: boolean
  hideTbdOption?: boolean
}

function EventMeetingFormFields({
  productKey = null,
  organizationKey = null,
  form,
  hideHosts,
  formControlVariant = "two-column",
  testid = "EventMeetingFormFields",
  eventVariant,
  hideTbdOption,
}: Props) {
  const ref = useRef<HTMLDivElement | null>(null)
  const activeOrganization = useActiveOrganization()!
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const memberLabel = useLabel("admin_member")
  const classes = useStyles()

  const product = useFragment<EventMeetingFormFields_ProductFragment$key>(
    graphql`
      fragment EventMeetingFormFields_ProductFragment on Product {
        id
        organization {
          meetingProviders(platform: zoom) {
            edges {
              node {
                id
                ...OrganizationZoomConnectionListItemFragment
              }
            }
          }
        }
        ...OccurrencePhysicalAddressInput_ProductFragment
      }
    `,
    productKey
  )

  const organization = useFragment<EventMeetingFormFields_OrganizationFragment$key>(
    graphql`
      fragment EventMeetingFormFields_OrganizationFragment on Organization {
        id
        meetingProviders(platform: zoom) {
          edges {
            node {
              id
              ...OrganizationZoomConnectionListItemFragment
            }
          }
        }
      }
    `,
    organizationKey
  )

  useEffect(() => {
    // if new meeting provider is added, prevent popover appearing by default on re-render
    handleClosePopover()
    // if the zoom meeting provider changes, ensure "group" meeting type is selected on default
    if (
      form.state.meetingProvider?.id !== form.state.initialMeetingProvider?.id &&
      !form.state.meetingProvider?.zoomConnection.hasWebinarAddon
    )
      form.state.zoomMeetingTypeRadioSelection = "group"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.meetingProvider?.id])

  const isCreationForm = form.state.isCreation

  if (!product && !organization) return null

  return (
    <>
      <DiscoFormControl
        errorMessages={
          form.errorsByField.meetingProviderId ||
          form.errorsByField.meetingUrl ||
          form.errorsByField.videoRoomType ||
          form.errorsByField.physicalAddress
        }
        title={"Location"}
        titleTooltip={{
          label: `How will ${memberLabel.plural} join this event?`,
          placement: "right",
        }}
        variant={formControlVariant}
      >
        <DiscoTabs
          tabVariant={"grey-track"}
          routes={[
            {
              label: "Zoom",
              testid: "zoom",
              active: form.state.meetingTab === "zoom",
              leftIcon: <ZoomIcon />,
              onClick: () => (form.state.meetingTab = "zoom"),
            },
            {
              label: "Link",
              testid: "custom-link",
              leftIcon: <DiscoIcon icon={"link"} />,
              active: form.state.meetingTab === "link",
              onClick: () => (form.state.meetingTab = "link"),
            },
            {
              label: "In-Person",
              testid: "in-person",
              leftIcon: <DiscoIcon icon={"pin"} />,
              active: form.state.meetingTab === "in-person",
              onClick: () => (form.state.meetingTab = "in-person"),
            },
            ...ArrayUtils.spreadIf(
              {
                label: "TBD",
                testid: "tbd",
                leftIcon: <DiscoIcon icon={"question"} active />,
                active: form.state.meetingTab === "tbd",
                onClick: () => (form.state.meetingTab = "tbd"),
              },
              !hideTbdOption
            ),
          ]}
          testid={`${testid}.Tab`}
          className={classes.tabs}
          classes={{ arrowsContainer: classes.arrowsContainer }}
        />

        {renderFields()}
      </DiscoFormControl>

      {!hideHosts && (
        <EventHostsFormFields
          productId={eventVariant === "product" ? product?.id : null}
          form={form}
          testid={testid}
          formControlVariant={formControlVariant}
        />
      )}
    </>
  )

  function renderFields() {
    switch (form.state.meetingTab) {
      case "zoom":
        return (
          <>
            {!isCreationForm &&
              form.initialState.videoRoomType !== "custom-link" &&
              !form.state.initialMeetingProvider && (
                <DiscoAlert
                  severity={"warning"}
                  message={
                    "The Zoom account for this event was disconnected. Create a new Zoom meeting or webinar by selecting a host and saving changes."
                  }
                />
              )}

            {form.state.meetingProvider ? (
              <>
                <OrganizationZoomConnectionListItem
                  ref={ref}
                  meetingProviderData={toJS(form.state.meetingProvider)}
                  hideOverflow
                  rightSideContent={
                    <DiscoIconButton
                      onClick={() => {
                        setAnchorEl(ref.current)
                      }}
                      className={classes.chevronButton}
                      testid={`${testid}.connections.dropdown`}
                    >
                      <DiscoIcon icon={"chevron"} />
                    </DiscoIconButton>
                  }
                />

                <Popover
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{ classes: { root: classes.popoverPaper } }}
                >
                  <OrganizationZoomConnectionList
                    itemProps={{
                      onClick: handleZoomHostSelect,
                      selectedZoomProviderId: form.state.meetingProvider?.id,
                      hideStatus: true,
                    }}
                  />
                  <AddZoomAccountButton />
                </Popover>
              </>
            ) : (
              <DiscoText marginTop={1.5}>{"No Zoom accounts connected."}</DiscoText>
            )}
            {form.state.meetingProvider?.zoomConnection.hasWebinarAddon && (
              <RadioGroup
                value={form.state.zoomMeetingTypeRadioSelection}
                onChange={(e) =>
                  (form.state.zoomMeetingTypeRadioSelection = e.target
                    .value as EventRoomChoices)
                }
                className={classes.zoomRadioGroup}
              >
                <DiscoRadio
                  testid={`${testid}.Radio.zoomMeeting.group`}
                  label={"Zoom meeting"}
                  controlClasses={{ root: classes.labelRoot }}
                  value={"group"}
                  checked={form.state.zoomMeetingTypeRadioSelection === "group"}
                />
                <DiscoRadio
                  testid={`${testid}.Radio.zoomMeeting.broadcast`}
                  label={"Zoom webinar"}
                  controlClasses={{ root: classes.labelRoot }}
                  value={"broadcast"}
                  checked={form.state.zoomMeetingTypeRadioSelection === "broadcast"}
                />
              </RadioGroup>
            )}
            {activeOrganization.viewerPermissions.has("integrations.manage") &&
              !form.state.meetingProvider && <AddZoomAccountButton />}
          </>
        )
      case "link":
        return (
          <DiscoFormControl
            error={Boolean(
              form.errorsByField.meetingUrl || form.errorsByField.videoRoomType
            )}
            label={"Link"}
            className={classes.customLinkField}
          >
            <DiscoLinkInput
              data-testid={`${testid}.Input.custom-link`}
              name={"custom-link"}
              value={form.state.customLink}
              onChange={(val) => (form.state.customLink = val)}
              placeholder={"meet.google.com/abc-defg-hij"}
            />
          </DiscoFormControl>
        )
      case "in-person":
        return (
          <DiscoFormControl
            error={Boolean(form.errorsByField.physicalAddress)}
            label={"Address"}
            className={classes.customLinkField}
          >
            <OccurrencePhysicalAddressInput
              testid={"EventMeegtingFormFields"}
              form={form}
              productKey={product}
            />
          </DiscoFormControl>
        )
      case "tbd":
        return (
          <DiscoAlert
            className={classes.tbdAlert}
            severity={"info"}
            message={
              "Location to be determined. This event can only be published once a location is set."
            }
          />
        )
      default:
        return null
    }
  }

  function AddZoomAccountButton() {
    const hasZoomAccounts = form.state.meetingProvider
    return (
      <CreateMeetingProviderButton>
        {(props) => (
          <WithEntitlement
            entitlement={hasZoomAccounts ? "multiple_zoom_accounts" : undefined}
          >
            {({ hasEntitlement }) => (
              <DiscoButton
                {...props}
                leftIcon={"add-circle"}
                color={"grey"}
                variant={"dashed"}
                width={"100%"}
                data-testid={`${testid}.zoom-connect-button`}
                className={classes.meetingProviderButton}
                disabled={!hasEntitlement}
              >
                {"Add Zoom Account"}
              </DiscoButton>
            )}
          </WithEntitlement>
        )}
      </CreateMeetingProviderButton>
    )
  }

  function handleZoomHostSelect(
    _: React.MouseEvent<HTMLDivElement>,
    meetingProvider: ZoomMeetingProviderDataShape
  ) {
    form.state.meetingProvider = meetingProvider
    handleClosePopover()
  }

  function handleClosePopover() {
    setAnchorEl(null)
  }
}

const useStyles = makeUseStyles((theme) => ({
  tabs: {
    width: "fit-content",
  },
  arrowsContainer: {
    [theme.breakpoints.down("sm")]: {
      gridTemplateRows: "1fr max-content",
    },
  },
  labelRoot: {
    padding: 0,
  },
  popoverPaper: {
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(2),
    margin: 0,
    width: "100%",
    maxWidth: "533px",
  },
  meetingProviderButton: {
    marginTop: theme.spacing(1.5),
  },
  zoomRadioGroup: {
    marginTop: theme.spacing(1.5),
  },
  chevronButton: {
    transform: "rotate(180deg)",
  },
  customLinkField: {
    marginBottom: 0,
  },
  tbdAlert: {
    marginTop: theme.spacing(0.5),
  },
}))

type SkeletonProps = {
  variant?: DiscoFormControlVariant
}

export const EventMeetingFormFieldsSkeleton: React.FC<SkeletonProps> = ({
  variant = "two-column",
}) => {
  return (
    <DiscoFormControlSkeleton
      variant={variant}
      input={
        <Grid item container xs={12} direction={"column"}>
          <Skeleton width={150} height={48} />
          <Skeleton width={"100%"} height={96} />
          <DiscoButtonSkeleton />
        </Grid>
      }
    />
  )
}

export default Relay.withSkeleton<Props & SkeletonProps>({
  component: observer(EventMeetingFormFields),
  skeleton: EventMeetingFormFieldsSkeleton,
})
