/**
 * @generated SignedSource<<1a866c0da1225b436bb26b3b8394181b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventMeetingFormFields_ProductFragment$data = {
  readonly id: string;
  readonly organization: {
    readonly meetingProviders: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"OrganizationZoomConnectionListItemFragment">;
        };
      }>;
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"OccurrencePhysicalAddressInput_ProductFragment">;
  readonly " $fragmentType": "EventMeetingFormFields_ProductFragment";
};
export type EventMeetingFormFields_ProductFragment$key = {
  readonly " $data"?: EventMeetingFormFields_ProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventMeetingFormFields_ProductFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventMeetingFormFields_ProductFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "platform",
              "value": "zoom"
            }
          ],
          "concreteType": "MeetingProviderNodeConnection",
          "kind": "LinkedField",
          "name": "meetingProviders",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MeetingProviderNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MeetingProvider",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "OrganizationZoomConnectionListItemFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "meetingProviders(platform:\"zoom\")"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OccurrencePhysicalAddressInput_ProductFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "2f707baffac539d76f8f15e0dbf2a22c";

export default node;
