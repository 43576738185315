import { CreateEventFormStore } from "@/organization/occurrence/create-form/CreateEventForm"
import RecurrenceFrequencyDropdown from "@/organization/occurrence/recurring-event-fields/custom-frequency-dropdown/RecurrenceFrequencyDropdown"
import MonthlyRecurrenceDropdown from "@/organization/occurrence/recurring-event-fields/MonthlyRecurrenceDropdown"
import RecurrenceDropdown from "@/organization/occurrence/recurring-event-fields/RecurrenceDropdown"
import RecurrenceWeekdayCheckbox from "@/organization/occurrence/recurring-event-fields/RecurrenceWeekdayCheckbox"
import {
  RECURRENCE_FREQUENCY_DROPDOWN,
  RRULE_DAYS,
} from "@/organization/occurrence/util/RecurringEventConstants"
import {
  getNthWeekdayOfTheMonth,
  getRRuleConstantDayFromDate,
  isLastDayOfKindInTheMonth,
  mapRRulesIntoSelectOptions,
} from "@/organization/occurrence/util/RecurringEventsUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoDivider,
  DiscoFormControl,
  DiscoInput,
  DiscoRadio,
  DiscoText,
} from "@disco-ui"
import DiscoDatePicker from "@disco-ui/date/DiscoDatePicker"
import { Grid, RadioGroup } from "@material-ui/core"
import { ArrayUtils } from "@utils/array/arrayUtils"
import useISODateTimeTz from "@utils/hook/useISODateTimeTz"
import { TestIDProps } from "@utils/typeUtils"
import { utcToZonedTime } from "date-fns-tz"
import { observer } from "mobx-react-lite"
import { useMemo } from "react"
import { RRule } from "rrule"

type EventEndType = "until" | "count"

interface EventRecurringEventFormFields extends TestIDProps {
  form: CreateEventFormStore
  disabled?: boolean
}

function EventRecurringEventFormFields({
  form,
  testid = "EventRecurringEventFormFields",
  disabled,
}: EventRecurringEventFormFields): JSX.Element {
  const { timezone } = useISODateTimeTz({
    timezone: form.state.timezone || undefined,
  })

  const { rruleOptions, rruleEnd, customRRule } = form.state.rruleConfig
  const classes = useStyles()

  // Memos here are necessary because they prevent infinite rerenders
  // in the Dropdown components, without them we break browsers :)

  const startDate = useMemo(
    () => new Date(form.state.startDatetime),
    [form.state.startDatetime]
  )

  const recurrenceDropdownOptions = useMemo(() => {
    return mapRRulesIntoSelectOptions(
      [
        {
          freq: RRule.DAILY,
        },
        {
          freq: RRule.WEEKLY,
          dtstart: undefined,
          bymonthday: [],
          byweekday: [getRRuleConstantDayFromDate(startDate).weekday],
        },
        {
          freq: RRule.MONTHLY,
          dtstart: undefined,
          bymonthday: [],
          byweekday: [getRRuleConstantDayFromDate(startDate).weekday],
          bysetpos: [getNthWeekdayOfTheMonth(startDate)],
        },
        // If the date selected is the last of that day in the month,
        // then add an option for the last day of that type in each month.
        ...ArrayUtils.spreadIf(
          {
            freq: RRule.MONTHLY,
            dtstart: undefined,
            bymonthday: [],
            byweekday: [getRRuleConstantDayFromDate(startDate).weekday],
            bysetpos: [-1],
          },
          isLastDayOfKindInTheMonth(startDate)
        ),
        {
          freq: RRule.YEARLY,
          bymonth: [startDate.getMonth() + 1],
          bymonthday: [startDate.getDate()],
        },
        { freq: undefined },
      ],
      startDate
    )
  }, [startDate])

  const monthlyRecurrenceDropdownOptions = useMemo(() => {
    return mapRRulesIntoSelectOptions(
      [
        {
          freq: RRule.MONTHLY,
          bymonthday: [startDate.getDate()],
        },
        {
          freq: RRule.MONTHLY,
          dtstart: undefined,
          bymonthday: [],
          byweekday: [getRRuleConstantDayFromDate(startDate).weekday],
          bysetpos: [getNthWeekdayOfTheMonth(startDate)],
        },
        // If the date selected is the last of that day in the month,
        // then add an option for the last day of that type in each month.
        ...ArrayUtils.spreadIf(
          {
            freq: RRule.MONTHLY,
            dtstart: undefined,
            bymonthday: [],
            byweekday: [getRRuleConstantDayFromDate(startDate).weekday],
            bysetpos: [-1],
          },
          isLastDayOfKindInTheMonth(startDate)
        ),
      ],
      startDate
    )
  }, [startDate])

  return (
    <>
      <Grid item xs>
        <DiscoFormControl label={"Repeat"}>
          <RecurrenceDropdown
            testid={"SetTemplateRecurrence"}
            form={form}
            options={recurrenceDropdownOptions}
          />
        </DiscoFormControl>
      </Grid>
      {customRRule && (
        <Grid item xs>
          <DiscoFormControl
            label={"Frequency"}
            description={"Repeat this event every:"}
            variant={"two-column"}
          >
            <div className={classes.customFrequencyContainer}>
              <DiscoInput
                value={rruleOptions?.interval}
                type={"number"}
                inputProps={{ min: 1 }}
                onChange={(e) =>
                  (form.state.rruleConfig.rruleOptions = {
                    ...rruleOptions,
                    interval: Number(e.target.value) || undefined,
                  })
                }
                data-testid={"CreateRecurringEvent.interval"}
              />
              <RecurrenceFrequencyDropdown
                testid={`CustomRecurrence.freq`}
                form={form}
                options={RECURRENCE_FREQUENCY_DROPDOWN}
              />
            </div>
            {rruleOptions?.freq === RRule.WEEKLY ? (
              <div className={classes.customDayMonthContainer}>
                <DiscoText className={classes.repeatText}>{"Repeat on: "}</DiscoText>
                <div className={classes.dayCheckboxes}>
                  {RRULE_DAYS.map((day) => {
                    const byWeekdays = (rruleOptions?.byweekday || []) as number[]
                    const isChecked = byWeekdays.some((val) => val === day.weekday)
                    return (
                      <RecurrenceWeekdayCheckbox
                        key={day.toString()}
                        testid={`weekday.select.${day.weekday}`}
                        name={"recurrence-day"}
                        checked={isChecked}
                        onChange={() => toggleCustomDays(day.weekday)}
                        label={day.toString()[0]}
                      />
                    )
                  })}
                </div>
              </div>
            ) : (
              <div className={classes.customDayMonthContainer}>
                <DiscoText className={classes.repeatText}>{"Repeat:"}</DiscoText>
                <MonthlyRecurrenceDropdown
                  testid={"SetMonthlyTemplateRecurrence"}
                  form={form}
                  options={monthlyRecurrenceDropdownOptions}
                />
              </div>
            )}
          </DiscoFormControl>
          <DiscoDivider marginBottom={2.5} />
        </Grid>
      )}
      <Grid item xs>
        <DiscoFormControl
          description={"When would you like for this event to end?"}
          errorMessages={form.errorsByField.rrule}
          variant={"two-column"}
        >
          <RadioGroup
            value={rruleEnd}
            className={classes.endGroup}
            onChange={(e) => {
              form.state.rruleConfig.rruleEnd = e.target.value as EventEndType
              form.state.rruleConfig.rruleOptions = {
                ...form.state.rruleConfig.rruleOptions,
                count: e.target.value === "until" ? undefined : rruleOptions?.count,
                until:
                  e.target.value === "count"
                    ? undefined
                    : rruleOptions?.until ||
                      new Date(
                        new Date(new Date(startDate)).setMonth(startDate.getMonth() + 2)
                      ),
              }
            }}
          >
            <div>
              <DiscoRadio
                variant={"simple"}
                testid={`${testid}.RecurringType.Radio.until.specific-date`}
                label={"On a specific date"}
                value={"until"}
                checked={rruleEnd === "until"}
              />
              {rruleEnd === "until" && (
                <DiscoDatePicker
                  disabled={disabled}
                  testid={`${testid}.end-date-select`}
                  onChange={(details) => {
                    if (details.value)
                      form.state.rruleConfig.rruleOptions = {
                        ...rruleOptions,
                        until: details.value,
                        count: undefined,
                      }
                  }}
                  value={
                    rruleOptions?.until
                      ? utcToZonedTime(rruleOptions?.until, timezone)
                      : utcToZonedTime(
                          new Date(startDate).setMonth(startDate.getMonth() + 2) ||
                            new Date(),
                          timezone
                        )
                  }
                  minDate={disabled ? undefined : new Date()}
                />
              )}
            </div>
            <div>
              <DiscoRadio
                variant={"simple"}
                testid={`${testid}.RecurringType.Radio.specific-number`}
                label={"After a number of occurrences"}
                value={"count"}
                checked={rruleEnd === "count"}
              />
              {rruleEnd === "count" && (
                <DiscoInput
                  value={rruleOptions?.count || 1}
                  type={"number"}
                  inputProps={{ min: 1 }}
                  onChange={(e) =>
                    (form.state.rruleConfig.rruleOptions = {
                      ...rruleOptions,
                      count: Number(e.target.value) || null,
                      until: undefined,
                    })
                  }
                  data-testid={"CreateRecurringEvent.count"}
                />
              )}
            </div>
          </RadioGroup>
        </DiscoFormControl>
      </Grid>
    </>
  )

  function toggleCustomDays(day: number) {
    const currentSelectedDays = [...(rruleOptions?.byweekday as number[])]
    const index = currentSelectedDays.indexOf(day)
    if (index >= 0) {
      currentSelectedDays.splice(index, 1)
      form.state.rruleConfig.rruleOptions = {
        ...rruleOptions,
        byweekday: currentSelectedDays,
      }
      return
    }
    currentSelectedDays.push(day)
    form.state.rruleConfig.rruleOptions = {
      ...rruleOptions,
      byweekday: currentSelectedDays,
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  customDayMonthContainer: {
    marginTop: theme.spacing(1.5),
  },
  endGroup: {
    gap: theme.spacing(1.5),
  },
  dayCheckboxes: {
    display: "flex",
    gap: theme.spacing(0.5),
    width: "100%",
  },
  repeatText: {
    display: "flex",
    gap: theme.spacing(0.5),
    whiteSpace: "nowrap",
  },
  customFrequencyContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gap: theme.spacing(1.5),
  },
}))

export default observer(EventRecurringEventFormFields)
