import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoCheckbox } from "@disco-ui"
import { FormControlLabelProps } from "@material-ui/core"
import classNames from "classnames"
import React from "react"

type DiscoCheckboxProps = {
  checked: boolean
  onChange?(checked: boolean): void
  testid?: string
} & Omit<FormControlLabelProps, "control" | "onChange">

const RecurrenceWeekdayCheckbox: React.FC<DiscoCheckboxProps> = (props) => {
  const { checked, onChange, label, testid, ...rest } = props

  const classes = useStyles()
  return (
    <DiscoCheckbox
      className={classNames(classes.base)}
      variant={"ellipse"}
      checked={checked}
      onChange={onChange ? () => onChange(!checked) : undefined}
      data-testid={`${testid}.input`}
      disabled={rest.disabled}
      label={label}
    />
  )
}

const useStyles = makeUseStyles({
  base: {
    margin: "0px",
  },
})

export default RecurrenceWeekdayCheckbox
