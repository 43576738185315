import { FrequencyDropdownOption } from "@/organization/occurrence/recurring-event-fields/custom-frequency-dropdown/RecurrenceFrequencyDropdown"
import { RRule } from "rrule"

export const RRULE_DAYS = [
  RRule.MO,
  RRule.TU,
  RRule.WE,
  RRule.TH,
  RRule.FR,
  RRule.SA,
  RRule.SU,
]

export const RECURRENCE_FREQUENCY_DROPDOWN: FrequencyDropdownOption[] = [
  {
    value: RRule.MONTHLY.toString(),
    title: "Month(s)",
    context: {
      index: 0,
    },
  },
  {
    value: RRule.WEEKLY.toString(),
    title: "Week(s)",
    context: {
      index: 1,
    },
  },
]
