/**
 * @generated SignedSource<<ac00eda3066d91f7db1993eaad7e6f01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GoogleMapsPlaceToAddressInput = {
  productId?: string | null;
  organizationId: string;
  placeId: string;
};
export type OccurrencePhysicalAddressInputPlaceIdToAddressQuery$variables = {
  input: GoogleMapsPlaceToAddressInput;
};
export type OccurrencePhysicalAddressInputPlaceIdToAddressQuery$data = {
  readonly placeToAddress: {
    readonly displayAddress: string;
    readonly address: string;
    readonly name: string | null;
    readonly placeId: string;
    readonly lat: number;
    readonly lng: number;
  } | null;
};
export type OccurrencePhysicalAddressInputPlaceIdToAddressQuery = {
  variables: OccurrencePhysicalAddressInputPlaceIdToAddressQuery$variables;
  response: OccurrencePhysicalAddressInputPlaceIdToAddressQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GoogleMapsPlaceToAddressResponse",
    "kind": "LinkedField",
    "name": "placeToAddress",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "placeId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lat",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lng",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OccurrencePhysicalAddressInputPlaceIdToAddressQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OccurrencePhysicalAddressInputPlaceIdToAddressQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7b28cca613d57caf35574114feaed905",
    "id": null,
    "metadata": {},
    "name": "OccurrencePhysicalAddressInputPlaceIdToAddressQuery",
    "operationKind": "query",
    "text": "query OccurrencePhysicalAddressInputPlaceIdToAddressQuery(\n  $input: GoogleMapsPlaceToAddressInput!\n) {\n  placeToAddress(input: $input) {\n    displayAddress\n    address\n    name\n    placeId\n    lat\n    lng\n  }\n}\n"
  }
};
})();

(node as any).hash = "ae257a42a3d4b46a42aef1b29e7868d5";

export default node;
