import { CreateEventFormStore } from "@/organization/occurrence/create-form/CreateEventForm"
import { RecurrenceDropdownOption } from "@/organization/occurrence/recurring-event-fields/MonthlyRecurrenceDropdown"
import { getRuleType } from "@/organization/occurrence/util/RecurringEventsUtils"
import { DiscoSelect, DiscoText } from "@disco-ui"
import { useEffect, useState } from "react"
import { rrulestr } from "rrule"

interface Props {
  testid: string
  form: CreateEventFormStore
  options: RecurrenceDropdownOption[]
}

function RecurrenceDropdown({ testid, form, options }: Props) {
  const { rruleOptions } = form.state.rruleConfig

  const [currentOption, setCurrentOption] = useState(options[0])

  // When date changes, option RRULE strings change and we need to select
  // the same rrule option from the dropdown.
  useEffect(() => {
    let selected = currentOption
    if (currentOption.value.includes("FREQ=DAILY")) {
      selected = options.find((o) => o.value.includes("FREQ=DAILY"))!
    } else if (currentOption.value.includes("FREQ=WEEKLY")) {
      selected = options.find((o) => o.value.includes("FREQ=WEEKLY"))!
    } else if (currentOption.value.includes("FREQ=MONTHLY")) {
      // Monthly on last was selected. If no longer an option, go back to first option.
      if (currentOption.value.includes("BYSETPOS=-1")) {
        selected =
          options.find(
            (o) => o.value.includes("FREQ=MONTHLY") && o.value.includes("BYSETPOS=-1")
          ) || options[0]
      } else {
        selected = options.find((o) => o.value.includes("FREQ=MONTHLY"))!
      }
    } else if (currentOption.value.includes("FREQ=YEARLY")) {
      selected = options.find((o) => o.value.includes("FREQ=YEARLY"))!
    } else if (currentOption.value === "custom-rule") {
      return
    }

    setCurrentOption(selected)
    form.state.rruleConfig.rruleOptions = {
      ...rrulestr(selected.value).options,
      until: rruleOptions?.until,
      count: rruleOptions?.count,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  return (
    <DiscoSelect
      testid={testid}
      placeholder={"Select Preset Recurrence"}
      disableClearable
      value={currentOption!.value}
      onChange={(value) =>
        handleSetCurrentOptions(options.find((o) => o.value === value)!)
      }
      options={options}
      renderOption={(option) => {
        return (
          <DiscoText truncateText={1} testid={`${testid}.item-${option.context!.index}`}>
            {option.title}
          </DiscoText>
        )
      }}
    />
  )

  function handleSetCurrentOptions(option: RecurrenceDropdownOption | null) {
    if (option) {
      setCurrentOption(option)
      if (option.value === "custom-rule") {
        form.state.rruleConfig.currentRuleType = "custom"
        form.state.rruleConfig.customRRule = true
        form.state.rruleConfig.rruleOptions = {
          ...options[0],
          until: rruleOptions?.until,
          count: rruleOptions?.count,
        }
      } else {
        form.state.rruleConfig.currentRuleType = getRuleType(
          rrulestr(option.value).options.freq
        )
        form.state.rruleConfig.customRRule = false
        form.state.rruleConfig.rruleOptions = {
          ...rrulestr(option.value).options,
          until: rruleOptions?.until,
          count: rruleOptions?.count,
        }
      }
    }
  }
}

export default RecurrenceDropdown
