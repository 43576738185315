/**
 * @generated SignedSource<<038d23df9458514fdddf5ad687a46580>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CancelRSVPToEventInput = {
  occurrenceId?: string | null;
  productId?: string | null;
};
export type OccurrenceAttendingButtonMutation$variables = {
  input: CancelRSVPToEventInput;
};
export type OccurrenceAttendingButtonMutation$data = {
  readonly response: {
    readonly occurrence: {
      readonly id: string;
      readonly hasViewerRSVPd: boolean;
      readonly atCapacity: boolean;
      readonly rsvps: {
        readonly totalCount: number;
      } | null;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type OccurrenceAttendingButtonMutation = {
  variables: OccurrenceAttendingButtonMutation$variables;
  response: OccurrenceAttendingButtonMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CancelRSVPToEventResponse",
    "kind": "LinkedField",
    "name": "cancelRsvpToEvent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Occurrence",
        "kind": "LinkedField",
        "name": "occurrence",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasViewerRSVPd",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "atCapacity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OccurrenceRSVPNodeConnection",
            "kind": "LinkedField",
            "name": "rsvps",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OccurrenceAttendingButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OccurrenceAttendingButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "747e4c20dbc619dfff4b598b9bada3ee",
    "id": null,
    "metadata": {},
    "name": "OccurrenceAttendingButtonMutation",
    "operationKind": "mutation",
    "text": "mutation OccurrenceAttendingButtonMutation(\n  $input: CancelRSVPToEventInput!\n) {\n  response: cancelRsvpToEvent(input: $input) {\n    occurrence {\n      id\n      hasViewerRSVPd\n      atCapacity\n      rsvps {\n        totalCount\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1cf5fa44cfc5f634002bb28d3b893d44";

export default node;
