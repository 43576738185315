import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabels } from "@/core/context/LabelsContext"
import CreateEventModal from "@/organization/occurrence/create-form/CreateEventModal"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButtonSkeleton, DiscoTooltip } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { useCallback, useRef, useState } from "react"

interface Props extends Omit<OverridableDiscoButtonProps, "modal"> {
  testid?: string
  onCreate?: (occurrence: { id: GlobalID; datetimeRange: readonly string[] }) => void
}

function CreateEventButton({
  children = "Add Event",
  testid = "CreateEventButton",
  onCreate,
  ...rest
}: Omit<Props, "children"> & { children?: OverridableDiscoButtonChildren }) {
  const activeProduct = useActiveProduct()
  const isMobile = useIsMobile()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const classes = useStyles()
  const { admin_experience: productLabel } = useLabels()
  const ModalComponent = useCallback(({ isOpen, onClose }) => {
    // Reset form state on each open
    if (!isOpen) return null
    return <CreateEventModal onClose={onClose} onCreate={onCreate} />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const hasNoProductMembership = activeProduct?.viewerMembership === null

  if (activeProduct) {
    return (
      <DiscoTooltip
        content={`You must register for this ${productLabel.singular} before you can create events in it.`}
        placement={"bottom"}
        disabled={!hasNoProductMembership}
      >
        <span className={hasNoProductMembership ? classes.buttonDisabled : ""}>
          <OverridableDiscoButton
            testid={`${testid}.create-event-button`}
            leftIcon={"add"}
            {...rest}
            modal={ModalComponent}
            disabled={hasNoProductMembership}
          >
            {typeof children === "string" ? (isMobile ? "Add" : children) : children}
          </OverridableDiscoButton>
        </span>
      </DiscoTooltip>
    )
  }

  return (
    <>
      {/* CTA */}
      <OverridableDiscoButton
        ref={buttonRef}
        testid={`${testid}.create-event-button`}
        leftIcon={"add"}
        onClick={handleClick}
        {...rest}
      >
        {typeof children === "string" ? (isMobile ? "Add" : children) : children}
      </OverridableDiscoButton>

      {/* Creation Modal */}
      {isModalOpen && (
        <CreateEventModal onClose={() => setIsModalOpen(false)} onCreate={onCreate} />
      )}
    </>
  )

  function handleClick() {
    setIsModalOpen(true)
  }
}

const useStyles = makeUseStyles(() => ({
  buttonDisabled: {
    cursor: "not-allowed",
  },
}))

export default Relay.withSkeleton<
  Omit<Props, "children"> & { children?: OverridableDiscoButtonChildren }
>({
  component: CreateEventButton,
  skeleton: DiscoButtonSkeleton,
})
