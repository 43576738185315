/**
 * @generated SignedSource<<7eb145d804d222a8b7ecf2df9e477d72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CreateEventForm_MembershipsQuery$variables = {
  membershipId: string;
};
export type CreateEventForm_MembershipsQuery$data = {
  readonly membership: {
    readonly id?: string;
    readonly adminMemberships?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly productId: string;
        };
      }>;
    } | null;
  } | null;
};
export type CreateEventForm_MembershipsQuery = {
  variables: CreateEventForm_MembershipsQuery$variables;
  response: CreateEventForm_MembershipsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "membershipId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "membershipId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "adminMemberships",
  "args": [
    {
      "kind": "Literal",
      "name": "appKind",
      "value": "events"
    },
    {
      "kind": "Literal",
      "name": "productType",
      "value": "course"
    },
    {
      "kind": "Literal",
      "name": "roles",
      "value": [
        "manager",
        "instructor"
      ]
    }
  ],
  "concreteType": "ProductMembershipNodeConnection",
  "kind": "LinkedField",
  "name": "productMemberships",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductMembershipNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductMembership",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "productMemberships(appKind:\"events\",productType:\"course\",roles:[\"manager\",\"instructor\"])"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateEventForm_MembershipsQuery",
    "selections": [
      {
        "alias": "membership",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "OrganizationMembership",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateEventForm_MembershipsQuery",
    "selections": [
      {
        "alias": "membership",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "OrganizationMembership",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cadda69c4c3a640df766fabb44b87e90",
    "id": null,
    "metadata": {},
    "name": "CreateEventForm_MembershipsQuery",
    "operationKind": "query",
    "text": "query CreateEventForm_MembershipsQuery(\n  $membershipId: ID!\n) {\n  membership: node(id: $membershipId) {\n    __typename\n    ... on OrganizationMembership {\n      id\n      adminMemberships: productMemberships(roles: [manager, instructor], productType: \"course\", appKind: events) {\n        edges {\n          node {\n            id\n            productId\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7d1f9088a7700f8d24feaabdaa19691f";

export default node;
