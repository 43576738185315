import { CreateEventFormStore } from "@/organization/occurrence/create-form/CreateEventForm"
import { EditEventDrawerFormStore } from "@/organization/occurrence/event-drawer/EditEventDrawerContext"
import useRefetchableZoomMeetingProviders from "@/zoom/util/hook/useRefetchableZoomMeetingProviders"
import { useEffect, useRef } from "react"

export type SupportedForms = CreateEventFormStore | EditEventDrawerFormStore

function useUpdateEventFormZoomProviders() {
  const formRef = useRef<SupportedForms | null>(null)
  const { zoomProviders } = useRefetchableZoomMeetingProviders()
  useEffect(() => {
    const form = formRef.current
    if (!form) return
    const updatedProviders = zoomProviders
    const isProviderExpired = !updatedProviders.find(
      (p) => p.id === form.state.meetingProvider?.id
    )

    // remove or add meeting provider as list of providers is updated
    if (form.state.meetingProvider && isProviderExpired) {
      delete form.state.meetingProvider
    }
    if (!form.state.meetingProvider && updatedProviders.length > 0) {
      form.state.meetingProvider = updatedProviders[0]
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomProviders])

  return { zoomProviders, formRef }
}

export default useUpdateEventFormZoomProviders
