import { ObservableState } from "@/core/form/store/FormStore"
import ChevronIcon from "@/core/ui/iconsax/linear/arrow-up-1.svg"
import { CreateEventFormStore } from "@/organization/occurrence/create-form/CreateEventForm"
import { OccurrenceReminderFormState } from "@/organization/occurrence/edit-form/EditEventForm"
import EventReminderPreviewEmailButton from "@/organization/occurrence/edit-form/EventReminderPreviewEmailButton"
import { EditEventDrawerFormStore } from "@/organization/occurrence/event-drawer/EditEventDrawerContext"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import DiscoEditor from "@components/editor/DiscoEditor"
import {
  DiscoChip,
  DiscoDivider,
  DiscoFormControl,
  DiscoIcon,
  DiscoIconButton,
  DiscoInput,
  DiscoSelect,
  DiscoText,
} from "@disco-ui"
import DiscoDurationInput from "@disco-ui/input/DiscoDurationInput"
import DiscoConfirmationModal from "@disco-ui/modal/DiscoConfirmationModal"
import DiscoMacrosTooltip from "@disco-ui/tooltip/DiscoMacrosTooltip"
import { Collapse } from "@material-ui/core"
import useDisclosure from "@utils/hook/useDisclosure"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import {
  displayGolangDuration,
  formatDateWithOptions,
  subtractGolangDuration,
} from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useEffect, useRef } from "react"

interface Props extends TestIDProps {
  form: EditEventDrawerFormStore | CreateEventFormStore
  reminder: ObservableState<OccurrenceReminderFormState>
}

function EventReminderFormField(props: Props) {
  const { form, reminder, testid = "EventReminderFormField" } = props

  const i = form.state.reminders!.indexOf(reminder)

  // When a new reminder is added scroll it into view
  const containerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (!reminder.id) {
      containerRef.current?.scrollIntoView({ behavior: "smooth" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { isOpen: confirmDelete, onToggle: toggleConfirmDelete } = useDisclosure()

  const { isOpen, onToggle, onOpen } = useDisclosure()
  const errors = form.errors.filter((e) => e.field.startsWith(`reminders.${i}`))
  const hasErrors = Boolean(errors.length)
  useEffect(() => {
    if (hasErrors) {
      onOpen()
      setTimeout(() => {
        containerRef.current?.scrollIntoView({ behavior: "smooth" })
      }, 500)
    }
  }, [hasErrors, onOpen])

  const classes = useStyles({ isOpen })

  const timezone = useUserTimezone()
  const reminderTriggerAt = subtractGolangDuration(
    new Date(form.state.startDatetime),
    reminder.timing
  )

  const disableForm = Boolean(reminder.sentAt)

  return (
    <div ref={containerRef} className={classes.root}>
      {/* Header row with title and buttons */}
      <div className={classes.header}>
        <div>
          <DiscoText
            variant={"body-sm-600"}
            marginRight={1}
            testid={`${testid}.timing-message`}
          >
            {`${displayGolangDuration(reminder.timing).toLowerCase()} before event`}
          </DiscoText>
          <DiscoText
            variant={"body-sm"}
            color={"text.secondary"}
            marginRight={1}
            testid={`${testid}.trigger-time`}
          >
            {formatDateWithOptions({
              format:
                DATE_FORMAT.DEFAULT_FULL_MONTH_WITH_SHORT_TIME_FORMAT_WITH_OFFSET_AND_AT,
              timeZone: timezone,
            })(reminderTriggerAt)}
          </DiscoText>
        </div>

        {reminder.sentAt ? (
          <DiscoChip label={"Sent"} color={"cyan"} testid={`${testid}.sent-indicator`} />
        ) : (
          <DiscoIconButton
            size={"small"}
            variant={"outlined"}
            onClick={toggleConfirmDelete}
            testid={`${testid}.delete`}
          >
            <DiscoIcon icon={"trash"} />
          </DiscoIconButton>
        )}

        <DiscoIconButton
          size={"small"}
          variant={"outlined"}
          onClick={handleDuplicate}
          testid={`${testid}.duplicate`}
        >
          <DiscoIcon icon={"copy"} />
        </DiscoIconButton>

        <DiscoIconButton
          size={"small"}
          variant={"outlined"}
          className={classes.expandButton}
          onClick={onToggle}
          disabled={hasErrors}
          testid={`${testid}.expand`}
        >
          <ChevronIcon width={24} height={24} />
        </DiscoIconButton>
      </div>

      {/* Inner form fields */}
      <Collapse in={isOpen}>
        <div>
          <DiscoDivider marginTop={2} marginBottom={2} />

          <DiscoFormControl
            label={
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {"Timing"}
              </DiscoText>
            }
            errorMessages={form.errorsByField[`reminders.${i}.timing`]}
          >
            <div className={classes.timingWrapper}>
              <DiscoDurationInput
                testid={`${testid}.timing`}
                value={reminder.timing}
                onChange={(v) => (reminder.timing = v)}
                disabled={disableForm}
              />
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {"before the event"}
              </DiscoText>
            </div>
          </DiscoFormControl>

          <DiscoFormControl
            label={
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {"Audience"}
              </DiscoText>
            }
            errorMessages={form.errorsByField[`reminders.${i}.audience`]}
          >
            <DiscoSelect
              value={reminder.audience}
              onChange={(v) => (reminder.audience = v!)}
              disableClearable
              disabled={disableForm}
              testid={`${testid}.audience`}
              options={[
                {
                  value: "all",
                  title: "All Members",
                  subtitle: "All Members with access to this event get a reminder email",
                },
                {
                  value: "rsvps",
                  title: "Confirmed attendance",
                  subtitle:
                    "Only Members who confirmed attendance to this event get a reminder email",
                },
                {
                  value: "non_rsvps",
                  title: "Have not confirmed attendance",
                  subtitle:
                    "Members with access to the event who have not confirmed attendance will get a reminder email",
                },
              ]}
              renderOption={(o) => (
                <div data-testid={`${testid}.audience.option.${o.value}`}>
                  <DiscoText variant={"body-md-600"}>{o.title}</DiscoText>
                  <DiscoText variant={"body-xs-500"} color={"text.secondary"}>
                    {o.subtitle}
                  </DiscoText>
                </div>
              )}
            />
          </DiscoFormControl>

          <DiscoFormControl
            label={
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {"Email Subject"}
              </DiscoText>
            }
            errorMessages={form.errorsByField[`reminders.${i}.emailSubject`]}
          >
            <DiscoInput
              value={reminder.emailSubject}
              onChange={(e) => (reminder.emailSubject = e.target.value)}
              disabled={disableForm}
              data-testid={`${testid}.email-subject`}
            />
          </DiscoFormControl>
          <DiscoFormControl
            label={
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {"Email Body"}
              </DiscoText>
            }
            errorMessages={form.errorsByField[`reminders.${i}.emailRichEditorBody`]}
          >
            <DiscoEditor
              defaultValue={reminder.emailRichEditorBody}
              onChange={(v) => (reminder.emailRichEditorBody = v)}
              config={"email"}
              variant={"input"}
              readOnly={disableForm}
              testid={`${testid}.email-body`}
            />
          </DiscoFormControl>

          <div className={classes.emailFooter}>
            <DiscoMacrosTooltip
              usePlace={"the email content"}
              macros={[
                "user.firstName",
                "user.lastName",
                "experience.name",
                "event.name",
                "event.dateAndTime",
              ]}
            />
            {"occurrenceId" in form.state && (
              <EventReminderPreviewEmailButton
                occurrenceId={form.state.occurrenceId}
                reminder={reminder}
                testid={`${testid}.preview-email`}
              />
            )}
          </div>
        </div>
      </Collapse>

      {/* Confirm delete modal */}
      <DiscoConfirmationModal
        testid={`${testid}.confirm-delete`}
        isOpen={confirmDelete}
        onClose={toggleConfirmDelete}
        title={`Are you sure you want to delete this reminder?`}
        description={"This will permanently delete the reminder and email content."}
        modalContentLabel={"Delete Reminder Confirmation"}
        disableTypeToConfirm
        confirmButtonProps={{
          onClick: () => form.state.reminders?.remove(reminder),
          children: "Yes, delete this reminder",
        }}
      />
    </div>
  )

  function handleDuplicate() {
    if (!form.state.reminders) return
    form.state.reminders.splice(i + 1, 0, {
      ...reminder,
      id: null,
      sentAt: null,
    })
  }
}

interface StyleProps {
  isOpen: boolean
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderRadius: theme.measure.borderRadius.large,
    boxShadow: theme.palette.groovyDepths.insideCard,
    marginBottom: theme.spacing(2),
  },
  header: {
    display: "grid",
    gridTemplateColumns: "1fr auto auto auto",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  timingWrapper: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  expandButton: (props: StyleProps) => ({
    transition: "transform 0.2s ease-in-out",
    transform: "rotate(90deg)",
    ...styleIf(props.isOpen, {
      transform: "rotate(180deg)",
    }),
  }),
  emailFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}))

export default observer(EventReminderFormField)
